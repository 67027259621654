/////////////////////////////////////////
/// #MainPageTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageTable.handsontable .htDimmed {
  color: #000;
}
// mainpage 左上ヘッダーのした線を消す
#MainPageTable.handsontable thead tr th:first-child {
  // border-bottom: none
}
// mainpage 列ヘッダーの背景色デフォルト
#MainPageTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
  // font-family: "ＭＳ Ｐゴシック", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif !important;
  height: 13px !important;
  line-height: 12px !important;  
  // padding: 3px 2px 2px 2px !important;
} 
#MainPageTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
  // font-family: "ＭＳ Ｐゴシック", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif !important;
  height: 13px !important;
  line-height: 12px !important;  
  padding: 3px 2px 2px 2px !important;
}
#MainPageTable.handsontable td.htDimmed.listbox {
  height: 19px !important;
}
// mainpage 列ヘッダー背景色
#MainPageTable.handsontable .header {
  background: #386ba7 !important;
  color: white;
}
#MainPageTable.handsontable .header_keppin {
  background: #ff6 !important;
  color: black;
}
// orderinstruction 列ヘッダー 下線なし
#MainPageTable.handsontable .header_noBorder {
  background: #386ba7 !important;
  color: white;
  border-bottom: 1px #386ba7 solid;
}
// settingitem 列ヘッダー背景色
#MainPageTable.handsontable .header_no { //No.
  background: #333 !important;
  color: white;
}
// settingitem 列ヘッダー 下線なし
#MainPageTable.handsontable .header_noBorder_no { //No.
  background: #333 !important;
  color: white;
  border-bottom: 1px #333 solid;
}

#MainPageTable.handsontable .row_header_grouping1 { //小計行
  background: #c6e0b4;
}
#MainPageTable.handsontable .row_header_grouping1.currentRow, 
#MainPageTable.handsontable .row_header_grouping1.area,
#MainPageTable.handsontable .row_header_grouping1.current {
  background: #E0FFE0;
}
// mainpage セル文字色
#MainPageTable.handsontable .cell_bold { //太字
  font-weight: bold;
}
#MainPageTable.handsontable .cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#MainPageTable.handsontable .cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#MainPageTable.handsontable .cell_border_top_bold {
  border-top: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold_gray {
  border-right: 1px #ccc solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold2 {
  border-right: 2px black solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold3 {
  border-right: 3px black double !important;
}
#MainPageTable.handsontable .cell_border_left_bold {
  border-left: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_left_bold_white {
  border-left: 1px white solid !important;
}
#MainPageTable.handsontable .cell_border_left_bold2 {
  border-left: 2px black solid !important;
}
#MainPageTable.handsontable .cell_fg_none {
  color: transparent !important;
}

#MainPageTable.handsontable .cell_state_editOther {
  // font-style: italic;
  color: #AA33FF;
}

//mainpage 入力可能
#MainPageTable.handsontable .cell_editable {
  // background: #FFFFE0;
  border: 3px #ccc double;
  padding: 0px 1px 0px 1px !important;
}
//mainpage 入力可能 選択時
#MainPageTable.handsontable .currentRow, 
#MainPageTable.handsontable .area,
#MainPageTable.handsontable .current {
  background: #E0FFFF;
}
//mainpage 入力可能 選択時
#MainPageTable.handsontable .cell_editable.currentRow, 
#MainPageTable.handsontable .cell_editable.area,
#MainPageTable.handsontable .cell_editable.current {
  background: #F6F2B3;
}
//mainpage 入力不可
#MainPageTable.handsontable .cell_uneditable {
  background: #DDD;
}
//mainpage 入力不可 選択時
#MainPageTable.handsontable .cell_uneditable.currentRow, 
#MainPageTable.handsontable .cell_uneditable.area,
#MainPageTable.handsontable .cell_uneditable.current {
  background: #D7D3C7;
}
//mainpage 入力エラー
#MainPageTable.handsontable .cell_error {
  background: #FFCCCC;
}
#MainPageTable.handsontable .dropdown_cell_error {
  background: #FFCCCC;
}

//mainpage 警告
#MainPageTable.handsontable .cell_warn {
  background: #FFB62F;
}
//mainpage 編集済
#MainPageTable.handsontable .cell_edited {
  background: #A9D08E;
}
//mainpage 入力エラー 選択時
#MainPageTable.handsontable .cell_error.currentRow, 
#MainPageTable.handsontable .cell_error.area,
#MainPageTable.handsontable .cell_error.current {
  background: #F6BFB3;
}
//mainpage 警告 選択時
#MainPageTable.handsontable .cell_warn.currentRow, 
#MainPageTable.handsontable .cell_warn.area,
#MainPageTable.handsontable .cell_warn.current {
  background: #df9f29;
}


/////////////////////////////////////////
/// #MainPageHistoryTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageHistoryTable.handsontable .htDimmed {
  color: #000;
}
#MainPageHistoryTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageHistoryTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
}
#MainPageHistoryTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
}
#MainPageHistoryTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageHistoryTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}

